import {
  ICigarLog,
  ICigarLogActionProps,
  ICigarLogFilters,
  ICigarLogListType,
} from '@models';
import { createAction, props } from '@ngrx/store';

export const MyCigarsFiltersUpdateAction = createAction(
  'MY_CIGARS_FILTERS_UPDATE',
  props<{
    listType: ICigarLogListType;
    filters: ICigarLogFilters;
  }>()
);
export const MyCigarsFiltersClearAction = createAction(
  'MY_CIGARS_FILTERS_CLEAR',
  props<{
    listType: ICigarLogListType;
  }>()
);
export const MyCigarsFiltersClearSuccess = createAction(
  'MY_CIGARS_FILTERS_CLEAR_SUCCESS',
  props<{
    listType: ICigarLogListType;
  }>()
);
export const MyCigarsRequestAction = createAction(
  'MY_CIGARS_REQUEST',
  props<{
    listType: ICigarLogListType;
    reset?: boolean;
  }>()
);
export const MyCigarsSuccessAction = createAction(
  'MY_CIGARS_SUCCESS',
  props<{
    listType: ICigarLogListType;
    cigarLogs: ICigarLog[];
    skip: number;
    total: number;
  }>()
);
export const MyCigarsGetOneRequestAction = createAction(
  'MY_CIGARS_GET_ONE_REQUEST',
  props<{
    listType: ICigarLogListType;
    cigarLogId: number;
  }>()
);
export const MyCigarsGetOneSuccessAction = createAction(
  'MY_CIGARS_GET_ONE_SUCCESS',
  props<{
    listType: ICigarLogListType;
    cigarLog: ICigarLog;
  }>()
);
export const MyCigarsCreateRequestAction = createAction(
  'MY_CIGARS_CREATE_REQUEST',
  props<ICigarLogActionProps>()
);
export const MyCigarsCreateSuccessAction = createAction(
  'MY_CIGARS_CREATE_SUCCESS',
  props<{
    listType: ICigarLogListType;
    cigarLog: ICigarLog;
  }>()
);

export const MyCigarsQuantityUpdateRequest = createAction(
  'MY_CIGARS_QUANTITY_UPDATE_REQUEST',
  props<{ cigarLog: ICigarLog; quantity: number }>()
);

export const MyCigarsQuantityUpdateSuccess = createAction(
  'MY_CIGARS_QUANTITY_UPDATE_SUCCESS',
  props<{ cigarLog: ICigarLog }>()
);

export const MyCigarsQuantityUpdateError = createAction(
  'MY_CIGARS_QUANTITY_UPDATE_ERROR',
  props<{ cigarLog: ICigarLog; error: string }>()
);

export const MyCigarsPriceUpdateRequest = createAction(
  'MY_CIGARS_PRICE_UPDATE_REQUEST',
  props<{ cigarLog: ICigarLog; price: number }>()
);

export const MyCigarsPriceUpdateSuccess = createAction(
  'MY_CIGARS_PRICE_UPDATE_SUCCESS',
  props<{
    cigarLog: ICigarLog;
  }>()
);

export const MyCigarsPriceUpdateError = createAction(
  'MY_CIGARS_PRIC_UPDATEE_ERROR',
  props<{ cigarLog: ICigarLog; error: string }>()
);

export const MyCigarsUpdateRequestAction = createAction(
  'MY_CIGARS_UPDATE_REQUEST',
  props<{ cigarLog: ICigarLog }>()
);

export const MyCigarsUpdateSuccessAction = createAction(
  'MY_CIGARS_UPDATE_SUCCESS',
  props<{
    cigarLog: ICigarLog;
  }>()
);

export const MyCigarsDeleteRequestAction = createAction(
  'MY_CIGARS_DELETE_REQUEST',
  props<{ cigarLog: ICigarLog }>()
);
export const MyCigarsDeleteSuccessAction = createAction(
  'MY_CIGARS_DELETE_SUCCESS',
  props<{
    listType: ICigarLogListType;
    cigarLog: ICigarLog;
  }>()
);
export const MyCigarsDeleteFromSocket = createAction(
  'MY_CIGARS_DELETE_FROM_SOCKETS',
  props<{
    cigarLogId: number;
  }>()
);
export const MyCigarsErrorAction = createAction(
  'MY_CIGARS_ERROR',
  props<{ listType: ICigarLogListType; error: string }>()
);

export type ActionsUnion =
  | typeof MyCigarsFiltersUpdateAction
  | typeof MyCigarsRequestAction
  | typeof MyCigarsSuccessAction
  | typeof MyCigarsErrorAction
  | typeof MyCigarsGetOneRequestAction
  | typeof MyCigarsGetOneSuccessAction
  | typeof MyCigarsUpdateRequestAction
  | typeof MyCigarsUpdateSuccessAction
  | typeof MyCigarsDeleteRequestAction
  | typeof MyCigarsDeleteSuccessAction
  | typeof MyCigarsDeleteFromSocket
  | typeof MyCigarsPriceUpdateRequest
  | typeof MyCigarsPriceUpdateSuccess;
