import { Device } from '@capacitor/device';
import { Platform } from '@ionic/angular/standalone';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';

import { IScan, IRecognitionForm, IPicture } from '@models';

@Injectable({
  providedIn: 'root',
})
export class RecognitionService {
  constructor(private http: HttpClient, private platform: Platform) {}

  requestManualRecognition(form: IRecognitionForm) {
    return this.http.post<string>('recognition/manual', form);
  }

  reportBadResult(recordId) {
    return this.http.post<void>(`recognition/${recordId}/wrong`, {});
  }

  async submitPicture(
    image: IPicture,
    angle: number,
    x: number,
    y: number,
    width: number,
    height: number
  ) {
    const device = await Device.getInfo();

    let params = new HttpParams();
    params = params.set('Angle', `-${angle}`);
    params = params.set('X', x.toString());
    params = params.set('Y', y.toString());
    params = params.set('Width', width.toString());
    params = params.set('Height', height.toString());
    params = params.set('Version', '3.0');

    if (this.platform.is('capacitor')) {
      params = params.set('Platform', device.platform);
      params = params.set('DeviceManufacturer', device.manufacturer);
      params = params.set('DeviceModel', device.model.replace(/\s/g, ''));
    } else {
      params = params.set('Platform', 'Browser');
    }

    const formData: FormData = new FormData();
    formData.append('file', image.file);

    return this.http
      .post<IScan>('recognition/recognize', formData, {
        params: params,
      })
      .pipe(timeout(90000));
  }
}
