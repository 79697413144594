<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start" *ngIf="cigar.MyRating">
      <ion-button
        fill="clear"
        shape="round"
        color="danger"
        (click)="remove()"
        [strong]="true"
        [autofocus]="false"
      >
        <ion-icon name="trash-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Review</ion-title>
    <ion-buttons slot="end">
      <ion-button
        fill="outline"
        shape="round"
        color="primary"
        (click)="submit()"
        [strong]="true"
        [autofocus]="false"
        [disabled]="formGroup.invalid"
      >
        Submit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="formGroup" (ngSubmit)="submit()" novalidate>
    <ion-list lines="none">
      <ion-item *rxLet="formGroup.get('DrawRating'); let control">
        <ion-label>
          <p>Draw</p>
        </ion-label>
        <cs-rating-stars
          slot="end"
          [rating]="control.value"
          [size]="25"
          [rateable]="true"
          fillColor="#FDC70C"
          (rate)="control.setValue($event); control.markAsDirty()"
        />
      </ion-item>
      <ion-item *rxLet="formGroup.get('AppearanceRating'); let control">
        <ion-label>
          <p>Appearance</p>
        </ion-label>
        <cs-rating-stars
          slot="end"
          [rating]="control.value"
          [size]="25"
          [rateable]="true"
          fillColor="#FDC70C"
          (rate)="control.setValue($event); control.markAsDirty()"
        />
      </ion-item>
      <ion-item *rxLet="formGroup.get('BurnRating'); let control">
        <ion-label>
          <p>Burn</p>
        </ion-label>
        <cs-rating-stars
          slot="end"
          [rating]="control.value"
          [size]="25"
          [rateable]="true"
          fillColor="#FDC70C"
          (rate)="control.setValue($event); control.markAsDirty()"
        />
      </ion-item>
      <ion-item *rxLet="formGroup.get('AromaRating'); let control">
        <ion-label>
          <p>Aroma</p>
        </ion-label>
        <cs-rating-stars
          slot="end"
          [rating]="control.value"
          [size]="25"
          [rateable]="true"
          fillColor="#FDC70C"
          (rate)="control.setValue($event); control.markAsDirty()"
        />
      </ion-item>
      <ion-item *rxLet="formGroup.get('TasteRating'); let control">
        <ion-label>
          <p>Taste</p>
        </ion-label>
        <cs-rating-stars
          slot="end"
          [rating]="control.value"
          [size]="25"
          [rateable]="true"
          fillColor="#FDC70C"
          (rate)="control.setValue($event); control.markAsDirty()"
        />
      </ion-item>
      <ion-item>
        <ion-textarea
          tabindex="-1"
          labelPlacement="floating"
          label="Comment"
          autocorrect="on"
          autocomplete="off"
          [autofocus]="false"
          [counter]="true"
          [autoGrow]="true"
          maxlength="4000"
          rows="8"
          formControlName="Comment"
        >
        </ion-textarea>
      </ion-item>
    </ion-list>
  </form>
</ion-content>
