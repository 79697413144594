import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import _memoize from 'lodash-es/memoize';
import { IonIcon } from "@ionic/angular/standalone";

@Component({
    selector: 'cs-rating-stars',
    standalone: true,
    imports: [CommonModule, IonIcon],
    template: `
    <div
      class="star-wrapper"
      *ngFor="let star of allStars; let i = index"
      (click)="changeRating(i + 1)"
      [style.width.px]="size"
      [style.height.px]="size"
    >
      <ion-icon
        class="outer-star"
        src="assets/images/outer-rating-star.svg"
        [style.width.px]="size"
        [style.height.px]="size"
        [style.color]="borderColor"
      ></ion-icon>
      <div
        class="inner-star"
        [style.width.%]="fillPercentage(i + 1)"
        [style.height.px]="size"
        [style.backgroundColor]="fillColor"
      ></div>
    </div>
    <span class="numbers" *ngIf="showNumbers && rating">
      <span>{{ rating }}</span>
      <span *ngIf="count">({{ count }})</span>
    </span>
    <span class="suffix" *ngIf="suffix">{{ suffix }}</span>
  `,
    styleUrls: ['rating-stars.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingStarsComponent implements OnInit, OnChanges {
    @Input() rating: number;
    @Input() count: number;
    @Input() showNumbers = false;
    @Input() size: number;
    @Input() prefix: string;
    @Input() suffix: string;
    @Input() fillColor = '#DD423E';
    @Input() borderColor = '#DBA6A5';
    @Input() rateable = false;
    @Input() setAll = false;
    @Output() rate = new EventEmitter<number>();
    @Output() setAllStars = new EventEmitter<number>();

    allStars = [];
    fullStars: number;
    incompleteStar: number;

    ngOnInit() {
        this.allStars.length = 5;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.rating.currentValue) {
            const [fullStars, incompleteStar] = this.memoizedSetRating(this.rating);
            this.fullStars = fullStars;
            this.incompleteStar = incompleteStar;
        }
    }

    fillPercentage(currentStar: number) {
        if (currentStar <= this.fullStars) {
            return 100;
        } else if (currentStar === this.fullStars + 1) {
            return this.incompleteStar;
        } else {
            return 0;
        }
    }

    changeRating(value: number) {
        if (this.rateable) {
            if (this.setAll) {
                this.setAllStars.emit(value);
            } else {
                this.rate.emit(value);
            }
        }
    }

    memoizedSetRating = _memoize(this.setRating);

    setRating(value: number) {
        const fullStars = Math.floor(value);
        return [fullStars, Math.round((value - fullStars) * 100)];
    }
}
