import { Injectable } from '@angular/core';
import {
  ActionSheetButton,
  ActionSheetController,
  AlertController,
  AlertInput,
} from '@ionic/angular/standalone';
import { MyNoteModal } from '@modals/my-note-modal/my-note';
import { MyRatingModal } from '@modals/my-rating-modal/my-rating';
import {
  ICigar,
  ICigarLineShape,
  ICigarLite,
  ICigarLog,
  ICigarLogHumidorId,
  ICigarLogListEnum,
  ICigarLogListType,
  ICigarReviewForm,
  IState,
} from '@models';
import { Store } from '@ngrx/store';
import { CigarsService } from '@services/cigars.service';
import { HumidorsService } from '@services/humidor.service';
import { ModalService } from '@services/modal.service';
import { ProductService } from '@services/product.service';
import { RecognitionService } from '@services/recognition.service';
import { ToastService } from '@services/toast.service';
import * as cigarsActions from '@store/actions/cigars';
import * as myCigarsActions from '@store/actions/my-cigars';
import {
  getCigarIdAndType,
  hasReviewDetails,
  prepateShapeNameForSelection,
} from '@utils/cigar';
import { isCigarLog } from '@utils/cigarlogs';
import debug from 'debug';
import { firstValueFrom } from 'rxjs';

import { AnalyticsService } from './analytics.service';
import { LoaderService } from './loader.service';

const log = debug('CigarListActionsService');

@Injectable({
  providedIn: 'root',
})
export class CigarListActionsService {
  constructor(
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private toastService: ToastService,
    private store: Store<IState>,
    private recognitionService: RecognitionService,
    private humidorsService: HumidorsService,
    private loader: LoaderService,
    private productService: ProductService,
    public modalService: ModalService,
    private cigarsService: CigarsService,
    private analyticsService: AnalyticsService
  ) {}

  public addToRequest(
    listType: ICigarLogListType,
    item:
      | {
          cigarLogId?: number;
          LineId: number;
          ProductId?: number;
        }
      | {
          cigarLogId?: number;
          LineId?: number;
          ProductId: number;
        }
  ) {
    log('addToRequest', listType, item);
    this.store.dispatch(
      myCigarsActions.MyCigarsCreateRequestAction({
        listType,
        ...item,
      })
    );
  }

  async addTo(toAdd: ICigar | ICigarLog, from?: ICigarLogListType) {
    log('addTo', toAdd, from);
    try {
      const islog = isCigarLog(toAdd);
      const lists: { text: string; id: ICigarLogListType }[] = [
        { text: 'Journal', id: ICigarLogListEnum.JOURNAL_LIST },
        { text: 'Favorites', id: ICigarLogListEnum.FAVORITES_LIST },
        { text: 'Wish List', id: ICigarLogListEnum.WISH_LIST },
        { text: 'Humidors', id: 'humidors' },
      ];
      const buttons: { text: string; role: string }[] = lists
        .map(({ text, id }) => ({
          text,
          role: `add`,
          data: {
            id,
          },
        }))
        .filter((b) => b.data.id !== from);

      const { role, data } = await this.createActionSheet(
        `Add ${islog ? toAdd.CigarDetails.Name : toAdd.Name} to:`,
        buttons
      );
      if (role === 'add' && data?.id) {
        if (data.id === 'humidors') {
          this.addToHumidors(toAdd, from);
        } else {
          this.analyticsService.addTo(data.id);
          this.addToRequest(data.id, {
            cigarLogId: islog ? toAdd.Id : undefined,
            ProductId: toAdd.ProductId,
            LineId: toAdd.LineId,
          });
        }
      }
    } catch (error) {
      this.toastService.showError();
    }
  }

  // async addToLists(toAdd: ICigar | ICigarLog, from?: ICigarLogListType) {
  //   log('addToLists', toAdd, from);
  //   try {
  //     const islog = isCigarLog(toAdd);
  //     const lists: { text: string; id: ICigarLogListIds }[] = [
  //       { text: 'My Journal', id: 'journal' },
  //       { text: 'Favorites', id: 'favorites' },
  //       { text: 'Wish List', id: 'wishlist' },
  //     ];
  //     const buttons: { text: string; role: string }[] = lists
  //       .map(({ text, id }) => ({
  //         text,
  //         role: `add`,
  //         data: {
  //           id,
  //         },
  //       }))
  //       .filter((b) => b.data.id !== from);

  //     const { role, data } = await this.createActionSheet(
  //       `Add ${islog ? toAdd.CigarDetails.Name : toAdd.Name} to:`,
  //       buttons
  //     );
  //     if (role === 'add' && data?.id) {
  //       this.analyticsService.addTo(data.id);
  //       this.addToRequest(data.id, {
  //         cigarLogId: islog ? toAdd.Id : undefined,
  //         ProductId: toAdd.ProductId,
  //         LineId: toAdd.LineId,
  //       });
  //     }
  //   } catch (error) {
  //     this.toastService.showError();
  //   }
  // }

  async getShapesActionSheet(toAdd: ICigar | ICigarLog) {
    try {
      const islog = isCigarLog(toAdd);
      // line, we need to get shapes if not present
      let shapes: ICigarLineShape[] =
        (islog ? toAdd.CigarDetails['Shapes'] : toAdd.Shapes) || [];
      if (!shapes?.length) {
        const cigar = await firstValueFrom(
          this.productService.getLine(toAdd.LineId)
        );
        shapes = cigar.Shapes || [];
      }
      const buttons = shapes.map((shape) => ({
        text: prepateShapeNameForSelection(
          islog ? toAdd.CigarDetails.Name : toAdd.Name,
          shape
        ),
        role: `add`,
        data: {
          id: shape.Id,
        },
      }));
      return this.createActionSheet('Select version', buttons);
    } catch (error) {}
  }

  async addToHumidors(toAdd: ICigar | ICigarLog, from?: ICigarLogHumidorId) {
    log('addToHumidors', toAdd, from);
    try {
      const islog = isCigarLog(toAdd);
      const humidors = await firstValueFrom(
        this.humidorsService.getHumidorNames()
      );

      const buttons: { text: string; role: string }[] = humidors
        .map((humidor) => ({
          text: humidor.Name,
          role: `add`,
          data: {
            id: humidor.Id,
          },
        }))
        .filter((b) => b.data.id !== from);

      const { role, data } = await this.createActionSheet(
        `Add ${islog ? toAdd.CigarDetails.Name : toAdd.Name} to:`,
        buttons
      );
      const humidorId = data?.id;
      if (role === 'add' && humidorId) {
        this.analyticsService.addTo('humidor');
        if (toAdd.ProductId) {
          // product
          this.addToRequest(humidorId, {
            cigarLogId: islog ? toAdd.Id : undefined,
            ProductId: toAdd.ProductId,
          });
        } else {
          // line
          const { role, data } = await this.getShapesActionSheet(toAdd);
          if (role === 'add' && data?.id) {
            this.addToRequest(humidorId, {
              ProductId: data.id,
            });
          }
        }
      }
    } catch (error) {
      this.toastService.showError();
    }
  }

  async createActionSheet(header: string, buttons: ActionSheetButton[]) {
    const actionSheet = await this.actionSheetCtrl.create({
      header,
      buttons: [...buttons, { text: 'Cancel', role: 'cancel' }],
    });
    await actionSheet.present();

    return actionSheet.onDidDismiss();
  }

  async createLineProductsActionSheet(line: ICigar) {}

  async remove(cigarLog: ICigarLog) {
    const alert = await this.alertCtrl.create({
      header: 'Remove cigar',
      message: 'Are you sure you want to remove this cigar?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Delete',
          handler: () => {
            this.store.dispatch(
              myCigarsActions.MyCigarsDeleteRequestAction({ cigarLog })
            );
          },
        },
      ],
    });

    return alert.present();
  }

  async openNoteModal(cigar: ICigar | ICigarLite) {
    try {
      const modal = await this.modalService.createCSModal(
        MyNoteModal,
        {
          cigar,
        },
        {
          mobile: {
            initialBreakpoint: 0.5,
            breakpoints: [0, 0.5, 1],
          },
        }
      );
      await modal.present();

      const { role, data } = (await modal.onDidDismiss()) as {
        role: string;
        data?: { Note: string };
      };
      if (role === 'submit' && data) {
        this.store.dispatch(
          cigarsActions.CigarUpdateNoteRequest({
            cigar,
            text: data.Note,
          })
        );
      } else if (role === 'remove') {
        this.store.dispatch(
          cigarsActions.CigarDeleteNoteRequest({
            cigar,
          })
        );
      }
    } catch (error) {
      this.loader.hide();
    }
  }

  async openReviewModal(cigar: ICigar | ICigarLite, defaultValue?: number) {
    try {
      let toRate: ICigar;
      if (hasReviewDetails(cigar)) {
        toRate = cigar;
      } else {
        this.loader.show();
        const { id, type } = getCigarIdAndType(cigar);

        toRate = await firstValueFrom(this.cigarsService.get(type, id));

        this.store.dispatch(
          cigarsActions.CigarSuccess({
            cigar: toRate,
          })
        );

        this.loader.hide();
      }

      const modal = await this.modalService.createCSModal(
        MyRatingModal,
        {
          cigar: toRate,
          defaultValue,
        },
        {
          mobile: {
            initialBreakpoint: 0.8,
            breakpoints: [0, 0.8, 1],
          },
        }
      );
      await modal.present();

      const { role, data } = (await modal.onDidDismiss()) as {
        role: string;
        data?: ICigarReviewForm;
      };
      if (role === 'submit' && data) {
        this.store.dispatch(
          cigarsActions.CigarUpdateReviewRequest({
            cigar: toRate,
            review: data,
          })
        );
      } else if (role === 'remove') {
        this.store.dispatch(
          cigarsActions.CigarDeleteReviewRequest({
            cigar,
          })
        );
      }
    } catch (error) {
      this.loader.hide();
    }
  }

  reportBadResult(recordId) {
    return this.recognitionService.reportBadResult(recordId);
  }

  async smokingTimeAlert(cigar: ICigar | ICigarLite) {
    const inputs = Array(39)
      .fill(10)
      .map((x, i) => i * 5)
      .filter((value) => ![5].includes(value)) // filter out 5 min wich is too short
      .map((value) => ({
        label: `${value} min`,
        type: 'radio',
        value,
        checked:
          (cigar['MyCigarFeatures']?.SmokingTime || cigar.SmokingTime) ===
          value,
      })) as AlertInput[];
    const alert = await this.alertCtrl.create({
      header: 'Smoking time',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Save',
          handler: (SmokingTime) => {
            this.store.dispatch(
              cigarsActions.CigarUpdateFeaturesRequest({
                cigar,
                form: { SmokingTime },
              })
            );
          },
        },
      ],
      inputs,
    });

    return alert.present();
  }

  async openPriceAlert(cigarLog: ICigarLog<ICigar | ICigarLite>) {
    const alert = await this.alertCtrl.create({
      header: 'Price',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Save',
          handler: (values) => {
            this.store.dispatch(
              myCigarsActions.MyCigarsPriceUpdateRequest({
                cigarLog,
                price: values[0],
              })
            );
          },
        },
      ],
      inputs: [
        {
          type: 'number',
          placeholder: 'Price',
          min: 0,
          value: cigarLog.Price,
        },
      ],
    });

    return alert.present();
  }
}
