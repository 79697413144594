import { props, createAction } from '@ngrx/store';
import {
  ICigarLite,
  ICigar,
  CigarTypes,
  ICigarNote,
  ICigarReviewForm,
  ICigarReview,
  ICigarFeaturesForm,
  IMyCigarFeatures,
  ICustomCigarForm,
  ICigarLogListType,
} from '@models';

export const CigarCreateCustomRequest = createAction(
  'CIGAR_CREATE_CUSTOM_REQUEST',
  props<{
    form: ICustomCigarForm;
    addTo?: ICigarLogListType;
  }>()
);
export const CigarCreateCustomSuccess = createAction(
  'CIGAR_CREATE_CUSTOM_SUCCESS',
  props<{ cigar: ICigar; addTo?: ICigarLogListType }>()
);
export const CigarCreateCustomError = createAction(
  'CIGAR_CREATE_CUSTOM_ERROR',
  props<{ error: string }>()
);

export const CigarUpdateCustomRequest = createAction(
  'CIGAR_UPDATE_CUSTOM_REQUEST',
  props<{ ProductId: number; form: ICustomCigarForm; redirectTo?: string }>()
);
export const CigarUpdateCustomSuccess = createAction(
  'CIGAR_UPDATE_CUSTOM_SUCCESS',
  props<{ cigar: ICigar }>()
);
export const CigarUpdateCustomError = createAction(
  'CIGAR_UPDATE_CUSTOM_ERROR',
  props<{ error: string }>()
);

export const CigarUpdateFeaturesRequest = createAction(
  'CIGAR_UPDATE_FEATURES_REQUEST',
  props<{ cigar: ICigar | ICigarLite; form: ICigarFeaturesForm }>()
);
export const CigarUpdateFeaturesSuccess = createAction(
  'CIGAR_UPDATE_FEATURES_SUCCESS',
  props<{ cigar: ICigar | ICigarLite; features: IMyCigarFeatures }>()
);
export const CigarUpdateFeaturesError = createAction(
  'CIGAR_UPDATE_FEATURES_ERROR',
  props<{ cigar: ICigar | ICigarLite; error: string }>()
);

export const CigarUpdateReviewRequest = createAction(
  'CIGAR_UPDATE_REVIEW_REQUEST',
  props<{ cigar: ICigar; review: ICigarReviewForm }>()
);
export const CigarUpdateReviewSuccess = createAction(
  'CIGAR_UPDATE_REVIEW_SUCCESS',
  props<{ cigar: ICigar; review: ICigarReview }>()
);
export const CigarUpdateReviewError = createAction(
  'CIGAR_UPDATE_REVIEW_ERROR',
  props<{ cigar: ICigar; error: string }>()
);

export const CigarDeleteReviewRequest = createAction(
  'CIGAR_DELETE_REVIEW_REQUEST',
  props<{ cigar: ICigar | ICigarLite }>()
);
export const CigarDeleteReviewSuccess = createAction(
  'CIGAR_DELETE_REVIEW_SUCCESS',
  props<{ cigar: ICigar | ICigarLite; review: null }>()
);
export const CigarDeleteReviewError = createAction(
  'CIGAR_DELETE_REVIEW_ERROR',
  props<{ cigar: ICigar | ICigarLite; error: string }>()
);

export const CigarUpdateNoteRequest = createAction(
  'CIGAR_UPDATE_NOTE_REQUEST',
  props<{ cigar: ICigar | ICigarLite; text: string }>()
);
export const CigarUpdateNoteSuccess = createAction(
  'CIGAR_UPDATE_NOTE_SUCCESS',
  props<{ cigar: ICigar | ICigarLite; note: ICigarNote }>()
);
export const CigarUpdateNoteError = createAction(
  'CIGAR_UPDATE_NOTE_ERROR',
  props<{ cigar: ICigar | ICigarLite; error: string }>()
);

export const CigarDeleteNoteRequest = createAction(
  'CIGAR_DELETE_NOTE_REQUEST',
  props<{ cigar: ICigar | ICigarLite }>()
);
export const CigarDeleteNoteSuccess = createAction(
  'CIGAR_DELETE_NOTE_SUCCESS',
  props<{ cigar: ICigar | ICigarLite; note: null }>()
);
export const CigarDeleteNoteError = createAction(
  'CIGAR_DELETE_NOTE_ERROR',
  props<{ cigar: ICigar | ICigarLite; error: string }>()
);

export const CigarRequest = createAction(
  'CIGAR_REQUEST',
  props<{ cigarType: CigarTypes; id: number }>()
);
export const CigarSuccess = createAction(
  'CIGAR_SUCCESS',
  props<{ cigar: ICigar }>()
);
export const CigarError = createAction(
  'CIGAR_ERROR',
  props<{ error: string }>()
);
