import {
  ICigar,
  CigarTypes,
  IProductSearch,
  ICigarLineShape,
  ICigarLite,
} from '@models';

export function isICigar(cigar: ICigar | ICigarLite): cigar is ICigar {
  return (cigar as ICigar).CanonicalUrl !== undefined;
}
export function hasReviewDetails(cigar: ICigar | ICigarLite): cigar is ICigar {
  return (
    isICigar(cigar) &&
    cigar.MyRating?.BurnRating &&
    typeof cigar.MyRating.BurnRating === 'number'
  );
}

export function isICigarLite(cigar: ICigar | ICigarLite): cigar is ICigarLite {
  return !isICigar(cigar);
}

export function isCigarAProduct(cigar: ICigar | ICigarLite | IProductSearch) {
  return !!cigar.ProductId;
}

export function getCigarIdAndType(cigar: ICigar | ICigarLite | IProductSearch) {
  const isProduct = isCigarAProduct(cigar);
  return {
    type: isProduct ? CigarTypes.PRODUCTS : CigarTypes.LINES,
    id: isProduct ? cigar.ProductId : cigar.LineId,
  };
}

export function getCigarIdAndTypeCreatedId(createdId: string) {
  const [type, id] = createdId.split('-');
  return {
    type: type === 'P' ? CigarTypes.PRODUCTS : CigarTypes.LINES,
    id: Number(id),
  };
}

/**
 * Cigars do not have id, the have a ProductId and a LineId
 */
export function getCigarCreatedId(cigar: ICigar | ICigarLite | IProductSearch) {
  return isCigarAProduct(cigar) ? `P-${cigar.ProductId}` : `L-${cigar.LineId}`;
}

export function prepateShapeNameForSelection(
  productName = '',
  shape: ICigarLineShape
) {
  return `${shape.Name.replace(productName, '').trim()} ${
    shape.Attributes.Shape ? '- ' + shape.Attributes.Shape : ''
  } ${
    shape.Attributes.Length && shape.Attributes.RingGauge
      ? `${shape.Attributes.Length} x ${shape.Attributes.RingGauge}`
      : ''
  } `;
}

export function validateUPC(upcCode: string): boolean {
  // Check if the length is 12 and all characters are digits
  if (upcCode.length !== 12 || !/^\d+$/.test(upcCode)) {
    return false;
  }

  // Calculate the sum of digits in odd positions
  let oddSum = 0;
  for (let i = 0; i < 11; i += 2) {
    oddSum += parseInt(upcCode[i]);
  }

  // Calculate the sum of digits in even positions
  let evenSum = 0;
  for (let i = 1; i < 11; i += 2) {
    evenSum += parseInt(upcCode[i]);
  }

  // Calculate the total sum
  const totalSum = oddSum * 3 + evenSum;

  // Calculate the check digit
  let checkDigit = totalSum % 10;
  if (checkDigit !== 0) {
    checkDigit = 10 - checkDigit;
  }

  // Compare the calculated check digit with the last digit of the barcode
  return checkDigit === parseInt(upcCode[11]);
}

export function validateEAN13(eanCode: string): boolean {
  if (eanCode.length !== 13 || !/^\d+$/.test(eanCode)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 12; i++) {
    const digit = parseInt(eanCode[i]);
    sum += i % 2 === 0 ? digit : digit * 3;
  }

  const checkDigit = (10 - (sum % 10)) % 10;

  return checkDigit === parseInt(eanCode[12]);
}

export function validateGTIN14(gtin: string): boolean {
  if (gtin.length !== 14 || !/^\d+$/.test(gtin)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 13; i++) {
    const digit = parseInt(gtin[i]);
    sum += i % 2 === 0 ? digit * 3 : digit;
  }

  const checkDigit = (10 - (sum % 10)) % 10;

  return checkDigit === parseInt(gtin[13]);
}
