import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertController, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonContent, IonList, IonItem, IonLabel, IonTextarea } from '@ionic/angular/standalone';
import { ICigar, IState } from '@models';
import { Store } from '@ngrx/store';
import { RxLet } from '@rx-angular/template/let';
import { ModalService } from '@services/modal.service';
import { RatingStarsComponent } from '@shared/components/rating-stars/rating-stars.component';
import { addIcons } from "ionicons";
import { trashOutline } from "ionicons/icons";

@Component({
    selector: 'my-rating',
    standalone: true,
    imports: [
        CommonModule,
        RatingStarsComponent,
        ReactiveFormsModule,
        RxLet,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonButton,
        IonIcon,
        IonTitle,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        IonTextarea
    ],
    templateUrl: 'my-rating.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyRatingModal implements OnInit {
    @Input() defaultValue;
    @Input() cigar: ICigar;

    formGroup = this.fb.group({
        DrawRating: [0, Validators.required],
        AppearanceRating: [0, Validators.required],
        BurnRating: [0, Validators.required],
        AromaRating: [0, Validators.required],
        TasteRating: [0, Validators.required],
        Comment: ['', Validators.maxLength(4000)],
    });

    constructor(
        protected store: Store<IState>,
        private modalService: ModalService,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        private alertCtrl: AlertController
    ) {
        addIcons({ trashOutline });
    }

    ngOnInit() {
        this.formGroup.patchValue({
            DrawRating: this.cigar.MyRating?.DrawRating || this.defaultValue,
            AppearanceRating:
                this.cigar.MyRating?.AppearanceRating || this.defaultValue,
            BurnRating: this.cigar.MyRating?.BurnRating || this.defaultValue,
            AromaRating: this.cigar.MyRating?.AromaRating || this.defaultValue,
            TasteRating: this.cigar.MyRating?.TasteRating || this.defaultValue,
            Comment: this.cigar.MyRating?.Comment || '',
        });
        this.cdr.markForCheck();
    }

    submit() {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }
        this.modalService.dismiss(this.formGroup.value, 'submit');
    }

    async remove() {
        const alert = await this.alertCtrl.create({
            header: 'You are about to delete this review',
            message: 'Are you sure?',
            buttons: [{ text: 'Cancel' }, { text: 'Yes', role: 'remove' }],
        });
        await alert.present();
        const { role } = await alert.onDidDismiss();
        if (role === 'remove') {
            this.modalService.dismiss(null, 'remove');
        }
    }
}
