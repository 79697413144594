import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  ActionSheetController,
  LoadingController,
} from '@ionic/angular/standalone';
import {
  CigarTypes,
  ICigar,
  ICigarDistinct,
  ICigarFeaturesForm,
  ICigarLineInfo,
  ICigarLite,
  ICigarNote,
  ICigarReview,
  ICigarReviewForm,
  ICustomCigarForm,
  IMyCigarFeatures,
} from '@models';
import { getCigarIdAndType, prepateShapeNameForSelection } from '@utils/cigar';
import queryString from 'query-string';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CigarsService {
  constructor(
    private http: HttpClient,
    private actionSheetCtrl: ActionSheetController,
    private loadingCtrl: LoadingController
  ) {}

  get(type: CigarTypes, id: number) {
    return this.http.get<ICigar>(`cigars/${type}/${id}`);
  }

  createCustom(form: ICustomCigarForm) {
    return this.http.post<ICigar>('cigars/custom/product', form);
  }

  updateCustom(ProductId: number, form: ICustomCigarForm) {
    return this.http.put<ICigar>(`cigars/custom/product/${ProductId}`, form);
  }

  saveNote(cigar: ICigar | ICigarLite, text: string) {
    const { type, id } = getCigarIdAndType(cigar);

    return this.http.post<ICigarNote>(
      `cigars/${type}/${id}/note`,
      prepareStringAsPayload(text),
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      }
    );
  }

  deleteNote(cigar: ICigar | ICigarLite) {
    const { type, id } = getCigarIdAndType(cigar);
    return this.http.delete<void>(`cigars/${type}/${id}/note`);
  }

  getReviews(
    cigar: ICigar,
    rating: number,
    withCommentOnly: boolean,
    take: number,
    skip: number
  ) {
    const { type, id } = getCigarIdAndType(cigar);
    let params = new HttpParams();
    params = params.set('take', take.toString());
    params = params.set('skip', skip.toString());
    params = params.set('apponly', 'false');
    params = params.set('withcommentonly', withCommentOnly.toString());
    params = params.set('rating', rating.toString());

    return this.http.get<ICigarReview[]>(`cigars/${type}/${id}/reviews`, {
      params: params,
    });
  }

  review(cigar: ICigar, review: ICigarReviewForm) {
    const { type, id } = getCigarIdAndType(cigar);

    return this.http.post<ICigarReview>(`cigars/${type}/${id}/rate`, review);
  }

  deleteReview(cigar: ICigar | ICigarLite) {
    const { type, id } = getCigarIdAndType(cigar);
    return this.http.delete<void>(`cigars/${type}/${id}/rate`);
  }

  saveFeatures(cigar: ICigar | ICigarLite, form: ICigarFeaturesForm) {
    const { type, id } = getCigarIdAndType(cigar);

    return this.http.post<IMyCigarFeatures>(
      `cigars/${type}/${id}/features`,
      form
    );
  }

  getMyDistinctList(skip: number, Search?: string) {
    let params = new HttpParams();
    params = params.set('take', environment.pageLimit.toString());
    params = params.set('skip', skip.toString());
    return this.http.get<ICigarDistinct[]>(
      `cigars/mydistinct?${queryString.stringify({ Search })}`,
      {
        params,
        observe: 'response',
      }
    );
  }

  getLineInfo(lineId: number) {
    return this.http.get<ICigarLineInfo>(`cigars/lines/${lineId}/info`);
  }

  async displayLineSizeChoice(
    LineId,
    includeBrand = false
  ): Promise<{ ProductId: number | null; LineId: number | null } | null> {
    const loader = await this.loadingCtrl.create();
    await loader.present();
    try {
      const cigar = await firstValueFrom(this.get(CigarTypes.LINES, LineId));
      loader.dismiss();

      let buttons = includeBrand
        ? [
            {
              text: 'Brand including all sizes',
              role: 'line',
              data: {
                result: { ProductId: null, LineId },
              },
            },
          ]
        : [];
      buttons = [
        ...buttons,
        ...cigar.Shapes.map((shape) => ({
          text: prepateShapeNameForSelection(cigar.Name, shape),
          role: 'product',
          data: {
            result: { ProductId: shape.Id, LineId: shape.LineId },
          },
        })),
      ];

      const actionSheet = await this.actionSheetCtrl.create({
        header: buttons.length
          ? includeBrand
            ? 'Select brand or specific size'
            : 'Select specific size'
          : 'No versions available sorry',
        buttons: [
          ...buttons,
          {
            text: 'Cancel',
            role: 'cancel',
          },
        ],
      });
      await actionSheet.present();
      const { role, data } = await actionSheet.onDidDismiss();
      if (role === 'line' || role === 'product') {
        return data.result;
      }
      return null;
    } catch (error) {
      loader.dismiss();
    }
  }
}

/**
 * This is legacy code. @todo find something better encodeURI / trim etc
 * @param text
 */
function prepareStringAsPayload(text) {
  return '"' + ('' + text).replace(/"/g, '\\"') + '"';
}
